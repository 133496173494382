import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/layout/Navbar";
import { POS } from "./pages/POS";
import { Tables } from "./pages/Tables";
import { Orders } from "./pages/Orders";
import { Reports } from "./pages/Reports";
import { Tips } from "./pages/Tips";
import { Products } from "./pages/Products";
import { LoginForm } from "./components/auth/LoginForm";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { Loading } from "./components/ui/Loading";

function AppContent() {
  const { user, setUser } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch("/api/session");
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        }
      } catch (error) {
        console.error("Session check failed:", error);
      } finally {
        setLoading(false);
      }
    };

    checkSession();
  }, [setUser]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[--body-bg-color]">
        <Loading />
      </div>
    );
  }

  if (!user) {
    return <LoginForm />;
  }

  return (
    <Router>
      <div className="h-screen flex flex-col">
        <Navbar />
        <div className="flex flex-col flex-1 overflow-hidden">
          <Routes>
            <Route path="/" element={<POS />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/tips" element={<Tips />} />
            <Route path="/products" element={<Products />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
