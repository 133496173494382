import { Loading } from "../components/ui/Loading";
import { useState, useEffect } from "react";
import type { RevenueChartComponent } from "../components/reports/RevenueChart";
import { Stats } from "../components/reports/Stats";
import { TeamStats } from "../components/reports/TeamStats";
import { TopProducts } from "../components/reports/TopProducts";

export function ReportsInner(props: { RevenueChart: RevenueChartComponent }) {
  const { RevenueChart } = props;
  return (
    <div className="p-8 space-y-8 overflow-y-auto">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold text-white">Reports</h1>
          <div className="flex items-center gap-2">
            <button className="flex items-center gap-1 px-3 py-1 bg-[--component-bg-color] rounded-lg text-gray-400 hover:text-white border border-gray-500">
              <i className="ri-building-line"></i>
              Properties{" "}
              <span className="bg-gray-700 px-1.5 rounded-full text-xs">2</span>
            </button>
            <button className="flex items-center gap-1 px-3 py-1 bg-[--component-bg-color] rounded-lg text-gray-400 hover:text-white border border-gray-500">
              <i className="ri-filter-line"></i>
              Filters{" "}
              <span className="bg-gray-700 px-1.5 rounded-full text-xs">2</span>
            </button>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button className="px-3 py-1 bg-[--component-bg-color] rounded-lg text-white border border-gray-500">
            Today <i className="ri-arrow-down-s-line"></i>
          </button>
        </div>
      </div>

      <Stats />

      <div className="bg-[--component-bg-color] rounded-lg p-4 border border-gray-500">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-4">
            <button className="text-white font-medium">Revenue</button>
            <button className="text-gray-400">TAX</button>
            <button className="text-gray-400">Cash</button>
          </div>
          <button className="text-sm text-gray-400 hover:text-white">
            See details <i className="ri-arrow-right-line"></i>
          </button>
        </div>
        <RevenueChart />
      </div>

      <div className="grid grid-cols-2 gap-8">
        <TopProducts />
        <div className="space-y-8">
          <TeamStats />
          <div className="bg-[--component-bg-color] rounded-lg p-4 border border-gray-500">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <h2 className="text-lg font-semibold">Reservations</h2>
                <span className="bg-red-500/20 text-red-500 px-2 py-0.5 rounded text-sm">
                  4 PENDING
                </span>
              </div>
              <button className="text-sm text-gray-400 hover:text-white">
                See details <i className="ri-arrow-right-line"></i>
              </button>
            </div>
            <div className="flex items-center gap-2 mb-4">
              <i className="ri-calendar-line text-2xl"></i>
              <div className="text-2xl font-semibold">40</div>
              <div className="text-gray-400">RESERVATIONS BOOKED</div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="text-2xl font-semibold">2</div>
                <div className="text-sm text-gray-400">waitlist</div>
              </div>
              <div>
                <div className="text-2xl font-semibold">1:20h</div>
                <div className="text-sm text-gray-400">
                  expected occupancy rate
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Reports() {
  const [loaded, setLoaded] = useState(false);
  const [revenueChart, setRevenueChart] = useState<{
    component: RevenueChartComponent | undefined;
  }>();
  const fetch = async () => {
    const mod = await import("../components/reports/RevenueChart");
    setRevenueChart({ component: mod.RevenueChart });
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  };
  useEffect(() => {
    fetch();
  }, []);
  if (loaded && revenueChart) {
    return (
      <ReportsInner
        RevenueChart={revenueChart.component as RevenueChartComponent}
      />
    );
  } else {
    return (
      <div className="w-full h-full flex-1 flex justify-center items-center">
        <Loading />
      </div>
    );
  }
}
