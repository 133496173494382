import { Product } from "../../types/pos";
import { ProductRow } from "./ProductRow";

interface ProductsTableProps {
  products: Product[];
  onToggleStatus: (productId: number, newStatus: boolean) => Promise<void>;
}

export function ProductsTable({
  products,
  onToggleStatus,
}: ProductsTableProps) {
  return (
    <div className="overflow-x-auto h-full border border-gray-700 rounded-md">
      <table className="table table-pin-rows bg-[--component-bg-color] text-white rounnded">
        <thead className="bg-[#000000] text-white">
          <tr>
            <th className="text-center">
              <div className="w-16"></div>
            </th>
            <th>Name</th>
            <th>Type</th>
            <th>Price</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <ProductRow
              key={product.id}
              product={product}
              onToggleStatus={onToggleStatus}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
