import { TableStatus } from "../../types";
import { formatPrice } from "../../lib/utils";
import { Link } from "react-router-dom";
import { Button } from "../ui/Button";
import { Fragment } from "react";

interface TableRowProps {
  tableStatus: TableStatus;
}

export function TableRow({ tableStatus }: TableRowProps) {
  const getTimeSinceFirstOrder = () => {
    if (tableStatus.orders.length === 0) return "-";

    const firstOrderDate = new Date(
      Math.min(
        ...tableStatus.orders.map((order) =>
          new Date(order.created_at).getTime(),
        ),
      ),
    );
    const now = new Date();
    const diffInMinutes = Math.floor(
      (now.getTime() - firstOrderDate.getTime()) / (1000 * 60),
    );

    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    }
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const getTableStatusIcon = () => {
    if (tableStatus.table.is_busy) {
      return <i className="ri-timer-line text-yellow-500 text-2xl"></i>;
    }
    return <i className="ri-checkbox-circle-line text-green-500 text-2xl"></i>;
  };

  const getTableTypeIcon = () => {};
  const isBusy = tableStatus.table.is_busy;
  return (
    <tr className="">
      <td className="">
        {getTableStatusIcon()}
        <span className="pl-2">{tableStatus.table.name}</span>
      </td>
      <td>
        {isBusy ? (
          <Link className="hover:underline" to="/orders">
            <span>{tableStatus.orders.length}</span>
            <i className="ml-2 ri-menu-unfold-line"></i>
          </Link>
        ) : (
          "-"
        )}{" "}
      </td>
      <td>{isBusy ? getTimeSinceFirstOrder() : "-"}</td>
      <td>{isBusy ? formatPrice(tableStatus.totalAmount.toString()) : "-"}</td>
      <td>{isBusy ? formatPrice(tableStatus.totalTips.toString()) : "-"}</td>
      <td className="text-right">
        {isBusy ? (
          <div className="gap-2 flex justify-end">
            <Link className="btn btn-neutral" to="/orders">
              <i className="px-1 ri-bill-line"></i> Orders
            </Link>
            <Link className="btn btn-neutral" to="/">
              <i className="px-1 ri-restaurant-line"></i> Add
            </Link>
            <Link className="btn btn-primary" to="/">
              <i className="px-1 ri-bank-card-2-line"></i> Pay
            </Link>
          </div>
        ) : null}
      </td>
    </tr>
  );
}
