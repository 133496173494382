import { useState } from "react";
import { CartItem } from "../../types/pos";
import { Button } from "../ui/Button";

interface NotesModalProps {
  items: CartItem[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (notes: { [key: string]: string }) => void;
}

export function NotesModal({
  items,
  isOpen,
  onClose,
  onSave,
}: NotesModalProps) {
  const [notes, setNotes] = useState<{ [key: string]: string }>({});

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(notes);
    onClose();
  };

  const getItemKey = (item: CartItem) => {
    return `${item.id}-${JSON.stringify(item.selectedBundles)}`;
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[--body-bg-color] text-white rounded-lg w-full max-w-md">
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold">Add Notes</h2>
          <Button
            variant="secondary"
            onClick={onClose}
            className="p-1 hover:bg-gray-700"
          >
            <i className="ri-close-large-line"></i>{" "}
          </Button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="p-4 space-y-4 max-h-[60vh] overflow-y-auto">
            {items.map((item) => (
              <div key={getItemKey(item)} className="space-y-2">
                <label className="block">
                  <span className="block text-sm font-medium text-gray-300 mb-1">
                    {item.name} (Qty: {item.quantity})
                  </span>
                  <textarea
                    value={notes[getItemKey(item)] || item.note || ""}
                    onChange={(e) =>
                      setNotes((prev) => ({
                        ...prev,
                        [getItemKey(item)]: e.target.value,
                      }))
                    }
                    className="w-full p-2 bg-[#2a3138] border border-gray-700 rounded text-white"
                    rows={2}
                    placeholder="Add a note for this item..."
                  />
                </label>
              </div>
            ))}
          </div>
          <div className="p-4 border-t border-gray-700">
            <div className="grid grid-cols-2 gap-2">
              <Button
                variant="secondary"
                onClick={onClose}
                className="w-full bg-gray-700 hover:bg-gray-600"
                type="button"
              >
                Cancel
              </Button>
              <Button variant="primary" className="w-full" type="submit">
                Save Notes
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
