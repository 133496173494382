import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { Product } from "../types/pos";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const searchProducts = (products: Product[], searchQuery: string) => {
  return products.filter((product) => {
    if (product.name) {
      const name = product.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036F]/g, "");
      const query = searchQuery
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036F]/g, "");
      // todo: use fuzzy search here
      return name.includes(query);
    }
  });
};

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
}

export function getStatusColor(status: string) {
  switch (status.toLowerCase()) {
    case "completed":
      return { background: "#b7e9fd" };
    case "ready":
      return { background: "#1f9947", color: "white" };
    case "preparing":
      return { background: "#ffbb33" };
    case "new":
      return { background: "darkturquoise" };
    case "cancelled":
      return { background: "indianred" };
    default:
      return { background: "gray" };
  }
}

export function formatPrice(price: string | number): string {
  return `CHF ${Number(price).toFixed(2)}`;
}
