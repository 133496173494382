import { NavLink, Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export function Navbar() {
  const linkClass = ({ isActive }: { isActive: boolean }) => {
    const base =
      " text-1xl inline-flex items-center px-1 pt-1 border-b-4 font-medium ";
    return isActive ? base + " border-[#047aff]" : base + " border-transparent";
  };

  const { user } = useAuth();

  const handleLogout = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/session", {
        method: "DELETE",
        credentials: "include", // Ensures cookies are sent with the request
      });
      if (!response.ok) {
        console.error("Failed to log out:", response.statusText);
      } else {
        window.location.reload(); // Reload the page after successful logout
      }
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <div className="bg-[--body-bg-color] text-white flex flex-col">
      <div className="flex flex-1">
        <Link to="/" className="pl-2 flex justify-center items-center text-2xl">
          Lemmon
        </Link>
        <div className="flex justify-between h-16 flex-1">
          <div className="flex flex-1 ">
            <div className="mx-auto sm:flex sm:space-x-8">
              <NavLink to="/" className={linkClass}>
                POS
              </NavLink>
              <NavLink to="/orders" className={linkClass}>
                Orders
              </NavLink>
              <NavLink to="/tables" className={linkClass}>
                Tables
              </NavLink>
              <NavLink to="/reports" className={linkClass}>
                Reports
              </NavLink>
              <NavLink to="/products" className={linkClass}>
                Products
              </NavLink>
              <NavLink to="/tips" className={linkClass}>
                Tips
              </NavLink>
            </div>
          </div>
        </div>
        <div className="pr-2 flex justify-center items-center">
          <div className="px-2 flex-col">
            <div>Hey, {user?.profile.name}</div>
            <div className="text-xs">{user?.restaurant.name}</div>
          </div>
          <a href="#" onClick={handleLogout}>
            <i className="ri-logout-box-r-line text-2xl"></i>
          </a>
        </div>
      </div>
      <div className="border-b border-gray-700" />
    </div>
  );
}
