import { Button } from "../ui/Button";
import { CartItem } from "../../types/pos";
import { useState } from "react";
import { NotesModal } from "./NotesModal";

interface CartProps {
  items: CartItem[];
  onIncrement: (
    id: number,
    selectedBundles?: CartItem["selectedBundles"],
  ) => void;
  onDecrement: (
    id: number,
    selectedBundles?: CartItem["selectedBundles"],
  ) => void;
  onRemove: (id: number, selectedBundles?: CartItem["selectedBundles"]) => void;
  onUpdateNotes: (notes: { [key: string]: string }) => void;
}

export function Cart({
  items,
  onIncrement,
  onDecrement,
  onRemove,
  onUpdateNotes,
}: CartProps) {
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);

  const total = items.reduce(
    (sum, item) => sum + parseFloat(item.price) * item.quantity,
    0,
  );

  const handleNotesSubmit = (notes: { [key: string]: string }) => {
    onUpdateNotes(notes);
  };

  return (
    <div className="flex flex-col h-full bg-[#273442] text-white border border-gray-700 rounded">
      <div className="flex-1 p-4 overflow-y-auto flex flex-col">
        {items.length === 0 && (
          <div className="flex flex-col text-center justify-center items-center flex-1">
            <h2 className="text-3xl font-bold">This cart is empty.</h2>{" "}
            <h3 className="text-1xl">
              Open the menu to add a new product to the list.
            </h3>
          </div>
        )}
        {items.map((item, index) => (
          <div
            key={`${item.id}-${index}`}
            className="py-2 border-b border-gray-700"
          >
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <h3 className="font-medium">{item.name}</h3>
                <p className="text-sm text-gray-400">CHF {item.price}</p>
                {item.note && (
                  <p className="text-sm text-gray-400 italic">
                    Note: {item.note}
                  </p>
                )}
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant="muted"
                  size="sm"
                  onClick={() => onDecrement(item.id, item.selectedBundles)}
                  className="p-1"
                >
                  <span className="">
                    <i className="ri-subtract-line"></i>{" "}
                  </span>
                </Button>
                <span className="w-8 bg-white text-black text-center border rounded">
                  {item.quantity}
                </span>
                <Button
                  variant="muted"
                  size="sm"
                  onClick={() => onIncrement(item.id, item.selectedBundles)}
                  className="p-1"
                >
                  <span className="">
                    <i className="ri-add-line"></i>{" "}
                  </span>
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => onRemove(item.id, item.selectedBundles)}
                  className="p-1 text-red-500"
                >
                  <span className="">
                    <i className="ri-delete-bin-line"></i>{" "}
                  </span>
                </Button>
              </div>
            </div>
            {item.selectedBundles && item.selectedBundles.length > 0 && (
              <div className="mt-1 ml-2">
                {item.selectedBundles.map((bundle, bundleIndex) => (
                  <div key={bundleIndex} className="text-sm text-gray-400">
                    <div className="font-medium hidden">{bundle.name}:</div>
                    <ul className="ml-0">
                      {bundle.options.map((option, optionIndex) => (
                        <li key={optionIndex}>
                          {option.title}
                          {option.price > 0 &&
                            ` (+${option.price.toFixed(2)} CHF)`}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="border-t border-gray-700 p-4">
        <div className="flex justify-between mb-4">
          <span className="text-lg">Total</span>
          <span className="text-lg font-bold">CHF {total.toFixed(2)}</span>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <Button
            variant="secondary"
            className="w-full"
            disabled={items.length === 0}
          >
            Take-away
          </Button>
          <Button
            variant="secondary"
            className="w-full"
            disabled={items.length === 0}
            onClick={() => setIsNotesModalOpen(true)}
          >
            Add a note
          </Button>
        </div>
        <div className="grid grid-cols-2 gap-2 mt-2">
          <Button
            variant="secondary"
            className="w-full"
            disabled={items.length === 0}
          >
            Pay
          </Button>
          <Button
            variant="primary"
            className="w-full bg-blue-500"
            disabled={items.length === 0}
          >
            Send to table
          </Button>
        </div>
      </div>
      <NotesModal
        items={items}
        isOpen={isNotesModalOpen}
        onClose={() => setIsNotesModalOpen(false)}
        onSave={handleNotesSubmit}
      />
    </div>
  );
}
