export function TeamStats() {
  return (
    <div className="bg-[--component-bg-color] rounded-lg p-4 border border-gray-500">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Team</h2>
        <button className="text-sm text-gray-400 hover:text-white">
          See details <i className="ri-arrow-right-line"></i>
        </button>
      </div>
      <div className="flex items-center gap-2 mb-4">
        <i className="ri-team-line text-2xl"></i>
        <div className="text-2xl font-semibold">23</div>
        <div className="text-gray-400">EMPLOYEES</div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <div className="text-2xl font-semibold">4</div>
          <div className="text-sm text-gray-400">active members</div>
        </div>
        <div>
          <div className="text-2xl font-semibold">36</div>
          <div className="text-sm text-gray-400">shifts planned</div>
        </div>
        <div>
          <div className="text-2xl font-semibold">54h</div>
          <div className="text-sm text-gray-400">total shift hours</div>
        </div>
      </div>
    </div>
  );
}
