import { useState, useEffect } from "react";
import { api } from "../data/api";
import { Service, Category, Product, CartItem } from "../types/pos";
import { Cart } from "../components/pos/Cart";
import { Catalog } from "../components/pos/Catalog";
import { BundleModal } from "../components/pos/BundleModal";
import { Loading } from "../components/ui/Loading";

export function POSInternal(props: {
  products: Product[];
  services: Service[];
}) {
  const { services, products } = props;
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null,
  );
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [bundleProduct, setBundleProduct] = useState<Product | null>(null);

  const handleServiceSelect = (service: Service) => {
    setSelectedService(service);
    setSelectedCategory(null);
  };

  const handleCategorySelect = (category: Category) => {
    setSelectedCategory(category);
  };

  const handleProductSelect = (product: Product) => {
    if (product.bundles?.length > 0 || product.is_custom) {
      setBundleProduct(product);
    } else {
      addToCart(product);
    }
  };

  const addToCart = (
    product: Product,
    selectedBundles?: CartItem["selectedBundles"],
    customPrice?: string,
  ) => {
    setCartItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (item) =>
          item.id === product.id &&
          JSON.stringify(item.selectedBundles) ===
            JSON.stringify(selectedBundles),
      );

      if (existingItemIndex >= 0) {
        return prevItems.map((item, index) =>
          index === existingItemIndex
            ? { ...item, quantity: item.quantity + 1 }
            : item,
        );
      }

      const basePrice = customPrice
        ? parseFloat(customPrice)
        : parseFloat(product.price);
      const bundlePrice =
        selectedBundles?.reduce(
          (total, bundle) =>
            total +
            bundle.options.reduce((sum, option) => sum + option.price, 0),
          0,
        ) || 0;

      const totalPrice = (basePrice + bundlePrice).toFixed(2);

      return [
        ...prevItems,
        {
          id: product.id,
          name: product.name,
          price: totalPrice,
          quantity: 1,
          selectedBundles,
        },
      ];
    });
  };

  const handleBundleConfirm = (
    selections: { bundleId: number; optionIds: number[] }[],
    customPrice?: string,
  ) => {
    if (!bundleProduct) return;

    const formattedBundles = selections.map(({ bundleId, optionIds }) => {
      const bundle = bundleProduct.bundles!.find((b) => b.id === bundleId)!;
      return {
        bundleId,
        name: bundle.name,
        options: optionIds.map((optionId) => {
          const option = bundle.options.find((o) => o.id === optionId)!;
          return {
            id: option.id,
            title: option.display.title,
            price: option.price,
          };
        }),
      };
    });

    addToCart(bundleProduct, formattedBundles, customPrice);
    setBundleProduct(null);
  };

  const handleIncrement = (
    id: number,
    selectedBundles?: CartItem["selectedBundles"],
  ) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id &&
        JSON.stringify(item.selectedBundles) === JSON.stringify(selectedBundles)
          ? { ...item, quantity: item.quantity + 1 }
          : item,
      ),
    );
  };

  const handleDecrement = (
    id: number,
    selectedBundles?: CartItem["selectedBundles"],
  ) => {
    setCartItems((prevItems) =>
      prevItems
        .map((item) =>
          item.id === id &&
          JSON.stringify(item.selectedBundles) ===
            JSON.stringify(selectedBundles) &&
          item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item,
        )
        .filter((item) => item.quantity > 0),
    );
  };

  const handleRemove = (
    id: number,
    selectedBundles?: CartItem["selectedBundles"],
  ) => {
    setCartItems((prevItems) =>
      prevItems.filter(
        (item) =>
          !(
            item.id === id &&
            JSON.stringify(item.selectedBundles) ===
              JSON.stringify(selectedBundles)
          ),
      ),
    );
  };

  const handleUpdateNotes = (notes: { [key: string]: string }) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        const itemKey = `${item.id}-${JSON.stringify(item.selectedBundles)}`;
        return {
          ...item,
          note: notes[itemKey] || item.note,
        };
      }),
    );
  };

  return (
    <div className="flex flex-1 overflow-hidden">
      <div
        style={{ width: 400 }}
        className="bg-[--body-bg-color] px-2 py-4 flex-shrink-0"
      >
        <Cart
          items={cartItems}
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          onRemove={handleRemove}
          onUpdateNotes={handleUpdateNotes}
        />
      </div>
      <div className="flex flex-col flex-1 overflow-hidden">
        <Catalog
          services={services}
          selectedService={selectedService}
          selectedCategory={selectedCategory}
          onServiceSelect={handleServiceSelect}
          onCategorySelect={handleCategorySelect}
          onProductSelect={handleProductSelect}
          products={products}
          cartItems={cartItems}
        />
      </div>
      {bundleProduct && (
        <BundleModal
          product={bundleProduct}
          bundles={bundleProduct.bundles || []}
          isOpen={true}
          onClose={() => setBundleProduct(null)}
          onConfirm={handleBundleConfirm}
        />
      )}
    </div>
  );
}

export function POS() {
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const fetch = async () => {
    const fetchedProducts = await api.getProducts();
    const fetchedServices = await api.getServices();
    setProducts(fetchedProducts);
    setServices(fetchedServices);
    setLoaded(true);
  };
  useEffect(() => {
    fetch();
  }, []);
  if (loaded) {
    return <POSInternal products={products} services={services} />;
  } else {
    return (
      <div className="w-full h-full flex-1 flex justify-center items-center">
        <Loading />
      </div>
    );
  }
}
