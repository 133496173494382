import { useState, useEffect } from "react";
import { api } from "../data/api";
import { TableStatus } from "../types";
import { TablesGrid } from "../components/tables/TablesGrid";
import { Loading } from "../components/ui/Loading";

export function Tables() {
  const [tables, setTables] = useState<TableStatus[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const data = await api.getTablesMeta();
        setTables(data);
      } catch (error) {
        console.error("Failed to fetch tables:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTables();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex-1 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="p-8 h-full flex flex-col">
      <div className="flex-1 overflow-auto">
        <TablesGrid tables={tables} />
      </div>
    </div>
  );
}
