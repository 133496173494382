import { Order, Product, Service, Cart, TableStatus } from "../types/pos";

export const api = {
  getServices: async (): Promise<Service[]> => {
    try {
      const response = await fetch("/api/catalog/services");
      if (!response.ok) {
        throw new Error("Failed to fetch services");
      }
      return response.json();
    } catch (error) {
      console.error("Error fetching services:", error);
      throw error;
    }
  },

  getProducts: async (): Promise<Product[]> => {
    try {
      const response = await fetch("/api/catalog/products");
      if (!response.ok) {
        throw new Error("Failed to fetch products");
      }
      return response.json();
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error;
    }
  },

  getOrders: async (): Promise<Order[]> => {
    try {
      const response = await fetch("/api/orders/active");
      if (!response.ok) {
        throw new Error("Failed to fetch orders");
      }
      return response.json();
    } catch (error) {
      console.error("Error fetching orders:", error);
      throw error;
    }
  },

  getTablesMeta: async (): Promise<TableStatus[]> => {
    // Stub data for now
    return [
      {
        id: 1,
        table: {
          id: 1,
          name: "Table 1",
          type: "indoor",
          is_busy: 1,
        },
        orders: [
          {
            id: 101,
            created_at: new Date(Date.now() - 5000000).toLocaleString(),
            parent_id: null,
            status: "active",
          },
          {
            id: 102,
            created_at: new Date().toLocaleString(),
            parent_id: 101,
            status: "active",
          },
        ],
        totalAmount: 156.5,
        totalTips: 15.65,
      },
      {
        id: 2,
        table: {
          id: 2,
          name: "Table 2",
          type: "outdoor",
          is_busy: 1,
        },
        orders: [
          {
            id: 201,
            created_at: new Date(Date.now() - 500000).toLocaleString(),
            parent_id: null,
            status: "active",
          },
        ],
        totalAmount: 85.2,
        totalTips: 8.52,
      },
      {
        id: 3,
        table: {
          id: 3,
          name: "Table 3",
          type: "indoor",
          is_busy: 0,
        },
        orders: [],
        totalAmount: 0,
        totalTips: 0,
      },
    ];
  },

  createOrder: async (cart: Cart): Promise<Order> => {
    return null;
  },
};
