import { getStatusColor } from "../../lib/utils";
import { Order, OrderItem } from "../../types/pos";

interface OrderCardProps {
  order: Order;
}

interface GroupedItems {
  [key: string]: OrderItem[];
}

export function OrderCard({ order }: OrderCardProps) {
  if (!order.items) return null;
  const groupedItems = order.items.reduce<GroupedItems>((acc, item) => {
    const type = item.food_type?.name || "Other";
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(item);
    return acc;
  }, {});

  const formattedTime = new Date(order.created_at).toLocaleTimeString("fr-CH", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const formattedDate = new Date(order.created_at).toLocaleDateString("fr-CH");
  const orderStatusColor = getStatusColor(order.status);
  return (
    <div
      data-date={formattedDate}
      className="bg-[#2B3847] rounded-lg mb-4 text-white overflow-hidden break-inside-avoid border border-gray-500 text-xs max-h-full flex flex-col"
    >
      <div className="px-2 py-2 bg-[#738599] flex items-center justify-between">
        <div className="flex items-center gap-1">
          <span className="text-1xl">
            {order.service_method === "takeaway" ? (
              <i className="ri-takeaway-line"></i>
            ) : (
              <i className="ri-restaurant-line"></i>
            )}
          </span>
          <span className="uppercase ">{order.service_method}</span>
        </div>
      </div>

      <div className="px-2 py-2 flex-1 overflow-y-auto">
        <div className="flex justify-between text-sm text-gray-400 mb-4">
          <div>
            <div className="text-white">#{order.display_id}</div>
            <div title={formattedDate} className="text-xs">
              {formattedTime}
            </div>
          </div>
          <div className="text-right font-bold">
            <div className="text-white">Table {order.table_id || 1}</div>
            <div className="text-xs">CHF {order.amount.toFixed(2)}</div>
          </div>
        </div>
        <div
          style={{ ...orderStatusColor }}
          className="cursor-pointer text-black text-center font-bold  px-3 py-2 rounded"
        >
          {order.status.toUpperCase()}
        </div>
        <div className="pt-2">
          {Object.entries(groupedItems).map(([type, items]) => (
            <div
              key={type}
              className="mb-3 last:mb-0 border border-gray-500 px-2 py-1 rounded"
            >
              <div className="py-1 rounded">{type.toUpperCase()}</div>
              <div className="space-y-3">
                {items.map((item) => (
                  <div key={item.id} className="flex items-start gap-3">
                    <div className="bg-white text-black rounded px-2 flex items-center justify-center flex-shrink-0">
                      {item.quantity}
                    </div>
                    <div className="flex-1">
                      <div className="">{item.product.name}</div>
                      {item.bundles && item.bundles.length > 0 && (
                        <div className=" text-gray-400 ">
                          {item.bundles.map((bundle, index) => (
                            <div key={index}>
                              {bundle.extra?.title}
                              {bundle.price > 0 &&
                                ` (+${bundle.price.toFixed(2)} CHF)`}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
