import { useState, useEffect } from "react";
import { api } from "../data/api";
import { Order } from "../types/pos";
import { OrderCard } from "../components/orders/OrderCard";
import { Loading } from "../components/ui/Loading";

export function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const fetchedOrders = await api.getOrders();
        setOrders(fetchedOrders);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex-1 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (orders.length === 0) {
    return (
      <div className="p-8">
        <h1 className="text-2xl font-bold mb-4 text-white">Orders</h1>
        <p className="text-gray-400">No orders available</p>
      </div>
    );
  }

  return (
    <div className="flex flex-1 flex-col overflow-hidden p-2">
      <div
        style={{
          columnWidth: 200,
          columnFill: "auto",
        }}
        className="overflow-x-auto"
      >
        {orders
          .sort((orderA, orderB) => {
            const a = new Date(orderA.created_at);
            const b = new Date(orderB.created_at);
            return b.getTime() - a.getTime();
          })
          .map((order) => (
            <OrderCard key={order.id} order={order} />
          ))}
      </div>
    </div>
  );
}
