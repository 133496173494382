interface Stat {
  label: string;
  value: string;
  change: number;
  status: "FINISHED" | "RUNNING" | "EXPECTED";
}

const stats: Stat[] = [
  {
    label: "Revenue",
    value: "172,30€",
    change: 2.03,
    status: "FINISHED",
  },
  {
    label: "TAX",
    value: "264,10€",
    change: 1.12,
    status: "RUNNING",
  },
  {
    label: "Cash",
    value: "15262,02€",
    change: 0.04,
    status: "EXPECTED",
  },
];

export function Stats() {
  return (
    <div className="grid grid-cols-3 gap-4">
      {stats.map((stat) => (
        <div
          key={stat.label}
          className="bg-[--component-bg-color] rounded-lg p-4 border border-gray-500"
        >
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-400">{stat.label}</span>
            <span
              className={`text-xs px-2 py-1 rounded ${
                stat.change > 0 ? "text-green-500" : "text-red-500"
              }`}
            >
              {stat.change > 0 ? "+" : ""}
              {stat.change}%
            </span>
          </div>
          <div className="text-2xl font-semibold mb-1">{stat.value}</div>
          <div className="text-sm text-gray-400">{stat.status}</div>
        </div>
      ))}
    </div>
  );
}
