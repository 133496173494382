interface TopProduct {
  id: number;
  name: string;
  description: string;
  sold: number;
  revenue: number;
  image: string;
}

const topProducts: TopProduct[] = [
  {
    id: 1,
    name: "Toast benedict",
    description: "Our most ordered toast with eggs and...",
    sold: 2652,
    revenue: 3345,
    image:
      "https://images.unsplash.com/photo-1608039829572-78524f79c4c7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3",
  },
  {
    id: 2,
    name: "Allo salad",
    description: "Mix of fresh handpicked greenery",
    sold: 1863,
    revenue: 2038,
    image:
      "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3",
  },
  {
    id: 3,
    name: "Chicken gyosas",
    description: "The best gyosas in town, with chicken",
    sold: 678,
    revenue: 1056,
    image:
      "https://images.unsplash.com/photo-1496116218417-1a781b1c416c?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3",
  },
  {
    id: 4,
    name: "Appetizers board",
    description: "A mix of nuts, ham, and delicious cheese",
    sold: 526,
    revenue: 934,
    image:
      "https://images.unsplash.com/photo-1432139509613-5c4255815697?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3",
  },
];

export function TopProducts() {
  return (
    <div className="bg-[--component-bg-color] rounded-lg p-4 border border-gray-500">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-semibold">Top products</h2>
          <div className="flex items-center gap-1 text-sm text-gray-400">
            <span className="flex items-center gap-1">
              <i className="ri-shopping-bag-line"></i>
              142
            </span>
            <span className="flex items-center gap-1">
              <i className="ri-arrow-down-line"></i>
              32
            </span>
          </div>
        </div>
        <button className="text-sm text-gray-400 hover:text-white">
          See details <i className="ri-arrow-right-line"></i>
        </button>
      </div>
      <div className="space-y-4">
        {topProducts.map((product) => (
          <div key={product.id} className="flex items-center gap-4">
            <span className="text-gray-400 w-4">{product.id}</span>
            <img
              src={product.image}
              alt={product.name}
              className="w-12 h-12 rounded-lg object-cover"
            />
            <div className="flex-1">
              <h3 className="font-medium">{product.name}</h3>
              <p className="text-sm text-gray-400">{product.description}</p>
            </div>
            <div className="text-right">
              <div>{product.sold} sold</div>
              <div className="text-gray-400">{product.revenue}€</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
