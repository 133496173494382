import { useState, useEffect } from "react";
import { api } from "../data/api";
import { Product } from "../types/pos";
import { ProductsTable } from "../components/products/ProductsTable";
import { Loading } from "../components/ui/Loading";
import { searchProducts } from "../lib/utils";

export function Products() {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const data = await api.getProducts();
      setProducts(data);
      setError(null);
    } catch (err) {
      setError("Failed to load products");
      console.error("Error fetching products:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleStatus = async (productId: number, isEnabled: boolean) => {
    // In a real application, this would make an API call to update the product status
    // For now, we'll just update the local state
    setProducts((currentProducts) =>
      currentProducts.map((product) =>
        product.id === productId
          ? { ...product, status: isEnabled ? "available" : "unavailable" }
          : product,
      ),
    );

    // Simulate API delay
    await new Promise((resolve) => setTimeout(resolve, 500));
  };

  const filteredProducts = searchQuery
    ? searchProducts(products, searchQuery)
    : products;

  if (loading) {
    return (
      <div className="w-full h-full flex-1 flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-8">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded"
          role="alert"
        >
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 h-full flex flex-col">
      <div className="mb-4">
        <label className="input input-bordered flex items-center gap-2 relative">
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5">
            <i className="ri-menu-search-line"></i>{" "}
          </span>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for any type of item on the menus"
            className="grow pl-6"
          />
        </label>
      </div>
      <div className="flex-1 overflow-auto">
        <ProductsTable
          products={filteredProducts}
          onToggleStatus={handleToggleStatus}
        />
      </div>
    </div>
  );
}
