import { useState } from "react";
import { Product } from "../../types/pos";
import { formatPrice } from "../../lib/utils";

interface ProductRowProps {
  product: Product;
  onToggleStatus: (productId: number, newStatus: boolean) => Promise<void>;
}

export function ProductRow({ product, onToggleStatus }: ProductRowProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    try {
      await onToggleStatus(product.id, e.target.checked);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <tr className="hover:bg-[#343d47]">
      <td className="text-center flex items-center justify-center">
        <div className=" gap-2 h-5 w-5">
          {isLoading ? (
            <span className="loading loading-spinner loading-sm"></span>
          ) : (
            <input
              type="checkbox"
              className="toggle toggle-success"
              checked={product.status === "available"}
              onChange={handleToggle}
            />
          )}
        </div>
      </td>
      <td className="font-medium">{product.name}</td>
      <td className="uppercase">{product.type}</td>
      <td>{formatPrice(product.price)}</td>
      <td className="max-w-md truncate">{product.description}</td>
    </tr>
  );
}
