import { createContext, useContext, useState, ReactNode } from "react";
import { RestaurantProfile, UserProfile } from "../types/pos";

export type AuthSession = {
  id: number;
  role: string;
  restaurant_id: number;
  restaurant: RestaurantProfile;
  profile: UserProfile;
};

interface AuthContextType {
  user: AuthSession | null;
  setUser: (user: AuthSession | null) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<AuthSession | null>(null);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
