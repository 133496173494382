import { TableStatus } from "../../types";
import { TableRow } from "./TableRow";

interface TablesGridProps {
  tables: TableStatus[];
}

export function TablesGrid({ tables }: TablesGridProps) {
  return (
    <div className="overflow-x-auto h-full border border-gray-700 rounded-md">
      <table className="table table-pin-rows bg-[--component-bg-color] text-white">
        <thead className="bg-[#000000] text-white text-1xl">
          <tr>
            <th></th>
            <th>Order count</th>
            <th>Duration</th>
            <th>Amount</th>
            <th>Tips</th>
            <th className=""></th>
          </tr>
        </thead>
        <tbody className="text-1xl">
          {tables.map((table) => (
            <TableRow key={table.id} tableStatus={table} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
