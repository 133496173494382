import { Fragment } from "react";
import { Service, Category, Product, CartItem } from "../../types/pos";
import { Button } from "../ui/Button";
import { Card } from "../ui/Card";
import { useState } from "react";
import { searchProducts } from "../../lib/utils";

interface CatalogProps {
  services: Service[];
  selectedService: Service | null;
  selectedCategory: Category | null;
  onServiceSelect: (service: Service) => void;
  onCategorySelect: (category: Category) => void;
  onProductSelect: (product: Product) => void;
  products: Product[];
  cartItems: CartItem[];
}

export function Catalog({
  services,
  selectedService,
  selectedCategory,
  onServiceSelect,
  onCategorySelect,
  onProductSelect,
  products,
  cartItems,
}: CatalogProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredProducts = searchQuery
    ? searchProducts(products, searchQuery)
    : [];

  const showSearch = searchQuery.length > 0;

  return (
    <Fragment>
      <div className="p-4 border-b border-gray-700">
        <label className="input input-bordered flex items-center gap-2 relative">
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5">
            <i className="ri-menu-search-line"></i>{" "}
          </span>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for any type of item on the menus"
            className="grow pl-6"
          />
        </label>
      </div>

      {!showSearch && (
        <>
          <div
            style={{ scrollbarWidth: "none" }}
            className="overflow-x-auto border-b border-gray-700"
          >
            <div className="flex gap-2 p-4">
              {services.map((service) => (
                <Button
                  key={service.id}
                  onClick={() => onServiceSelect(service)}
                  variant={
                    selectedService?.id === service.id
                      ? "muted-selected"
                      : "muted"
                  }
                  className="py-8 px-4  text-3xl "
                >
                  {service.name}
                </Button>
              ))}
            </div>
          </div>
          <div
            style={{ scrollbarWidth: "none" }}
            className="overflow-x-auto border-b border-gray-700"
          >
            {selectedService && (
              <div className="flex gap-2 p-4">
                {selectedService.categories.map((category) => (
                  <Button
                    key={category.id}
                    onClick={() => onCategorySelect(category)}
                    className="py-6 px-4  text-2xl "
                    variant={
                      selectedCategory?.id === category.id
                        ? "muted-selected"
                        : "muted"
                    }
                  >
                    {category.name}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </>
      )}

      <div className="flex-1 p-4 overflow-y-auto">
        <div className="grid grid-cols-4 gap-4">
          {showSearch
            ? filteredProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  onProductSelect={onProductSelect}
                  cartItems={cartItems}
                ></ProductCard>
              ))
            : selectedCategory &&
              products
                .filter((product) =>
                  selectedCategory.product_ids.includes(product.id),
                )
                .map((product) => (
                  <ProductCard
                    key={product.id}
                    product={product}
                    onProductSelect={onProductSelect}
                    cartItems={cartItems}
                  ></ProductCard>
                ))}
        </div>
      </div>
    </Fragment>
  );
}

const ProductCard = (props: {
  product: Product;
  cartItems: CartItem[];
  onProductSelect: Function;
}) => {
  const { product, onProductSelect } = props;
  const selectedCartItem = props.cartItems.find((el) => el.id === product.id);
  return (
    <Card
      key={product.id}
      onClick={() => onProductSelect(product)}
      style={
        selectedCartItem
          ? {
              backgroundColor: "var(--body-bg-color)",
              borderColor: "white",
              color: "white",
            }
          : {}
      }
      className="select-none cursor-pointer bg-[#394758] text-white border-gray-700 relative"
    >
      <h3 className="font-medium">{product.name}</h3>
      <p className="mt-2 font-semibold hidden">CHF {product.price}</p>

      {selectedCartItem && (
        <div className="absolute right-1 bottom-1">
          <div className="badge border border-white bg-white text-black">
            {selectedCartItem.quantity}
          </div>
        </div>
      )}
    </Card>
  );
};
