import { Bundle, Product } from "../../types/pos";
import { Button } from "../ui/Button";
import { cn } from "../../lib/utils";
import { useState } from "react";

interface BundleModalProps {
  product: Product;
  bundles: Bundle[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (
    selectedOptions: { bundleId: number; optionIds: number[] }[],
    customPrice?: string,
  ) => void;
}

export function BundleModal({
  product,
  bundles,
  isOpen,
  onClose,
  onConfirm,
}: BundleModalProps) {
  const [selectedOptions, setSelectedOptions] = useState<
    Record<number, number[]>
  >({});
  const [customPrice, setCustomPrice] = useState(product.price || "");

  const handleOptionToggle = (bundleId: number, optionId: number) => {
    setSelectedOptions((prev) => {
      const currentSelected = prev[bundleId] || [];
      const isSelected = currentSelected.includes(optionId);
      const bundle = bundles.find((b) => b.id === bundleId);

      if (!bundle) return prev;

      let newSelected: number[];
      if (isSelected) {
        newSelected = currentSelected.filter((id) => id !== optionId);
      } else {
        if (currentSelected.length >= bundle.limit) {
          newSelected = [...currentSelected.slice(1), optionId];
        } else {
          newSelected = [...currentSelected, optionId];
        }
      }

      return {
        ...prev,
        [bundleId]: newSelected,
      };
    });
  };

  const isValid = bundles.every((bundle) => {
    const selected = selectedOptions[bundle.id]?.length || 0;
    return selected >= bundle.min_limit && selected <= bundle.limit;
  });

  const handleConfirm = () => {
    if (!isValid) return;

    const formattedSelections = bundles.map((bundle) => ({
      bundleId: bundle.id,
      optionIds: selectedOptions[bundle.id] || [],
    }));

    onConfirm(formattedSelections, product.is_custom ? customPrice : undefined);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-[--body-bg-color] text-white rounded-lg w-full max-w-md">
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          <h2 className="text-xl font-semibold">Select supplements</h2>
          <Button
            variant="secondary"
            onClick={onClose}
            className="p-1 hover:bg-gray-700"
          >
            <i className="ri-close-large-line"></i>{" "}
          </Button>
        </div>

        <div className="p-4 max-h-[60vh] overflow-y-auto">
          {product.is_custom ? (
            <div className="mb-6">
              <label
                htmlFor="customPrice"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Enter Custom Price (CHF)
              </label>
              <input
                type="number"
                id="customPrice"
                value={customPrice}
                onChange={(e) => setCustomPrice(e.target.value)}
                className="w-full p-2 bg-[#2a3138] border border-gray-700 rounded text-white"
                step="0.01"
                min="0"
              />
            </div>
          ) : null}

          {bundles.map((bundle) => (
            <div key={bundle.id} className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-medium">{bundle.name}</h3>
                <span className="text-sm text-gray-400">
                  {`Select ${bundle.min_limit}-${bundle.limit}`}
                </span>
              </div>
              <div className="space-y-2">
                {bundle.options.map((option) => {
                  const isSelected =
                    selectedOptions[bundle.id]?.includes(option.id) || false;
                  return (
                    <div
                      key={option.id}
                      onClick={() => handleOptionToggle(bundle.id, option.id)}
                      className={cn(
                        "flex justify-between items-center p-3 rounded cursor-pointer",
                        "border border-gray-700 hover:bg-[#2a3138]",
                        isSelected && "bg-[#2a3138] border-blue-500",
                      )}
                    >
                      <div>
                        <p>{option.display.title}</p>
                        {option.display.description && (
                          <p className="text-sm text-gray-400">
                            {option.display.description}
                          </p>
                        )}
                      </div>
                      <span className="text-sm">
                        {option.price > 0
                          ? `+${option.price.toFixed(2)} CHF`
                          : "Included"}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>

        <div className="p-4 border-t border-gray-700">
          <div className="grid grid-cols-2 gap-2">
            <Button
              variant="secondary"
              onClick={onClose}
              className="w-full bg-gray-700 hover:bg-gray-600"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleConfirm}
              disabled={!isValid || (product.is_custom && !customPrice)}
              className="w-full"
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
